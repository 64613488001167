exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stylesheet-js": () => import("./../../../src/pages/stylesheet.js" /* webpackChunkName: "component---src-pages-stylesheet-js" */),
  "component---src-templates-journal-index-js": () => import("./../../../src/templates/JournalIndex.js" /* webpackChunkName: "component---src-templates-journal-index-js" */),
  "component---src-templates-loan-js": () => import("./../../../src/templates/Loan.js" /* webpackChunkName: "component---src-templates-loan-js" */),
  "component---src-templates-loans-index-js": () => import("./../../../src/templates/LoansIndex.js" /* webpackChunkName: "component---src-templates-loans-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-stories-index-js": () => import("./../../../src/templates/StoriesIndex.js" /* webpackChunkName: "component---src-templates-stories-index-js" */)
}

