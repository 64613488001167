import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import SanityLink from '~components/SanityLink'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Button = ({ className, small, large, flexi, black, blue, border, children, to, link, onClick, activeClassName, anchor }) => {
	const buttonStyles = css`
		height: ${small ? '38px': large ? '60px' : '45px'};
		background-color: ${blue ? 'var(--blue)' : black ? 'var(--black)' : 'var(--white)'};
		display: inline-flex;
		/* min-width: ${flexi ? '100%' : small ? '93px': large ? '211px' : '178px'}; */
		color: ${blue ? 'var(--white)' : black ? 'var(--white)' : 'var(--black)'};
		border: ${border ? '1px solid var(--black)' : 'none'};
		box-sizing: border-box;
		border-radius: 30px;
		text-align: center;
		padding: ${ small ? '10px 20px' : '10px 32px'};
		box-sizing: border-box;
		transition: background-color 0.3s, color 0.3s;
		align-items: center;
		justify-content: center;
		:hover{
			background-color: ${blue ? 'var(--white)' : black ? 'var(--white)' : 'var(--black)'};
			color: ${blue ? 'var(--black)' : black ? 'var(--black)' : 'var(--white)'};
		}
	`
	if (anchor) {
		return (
			<AnchorLink to={to} className={className + ` button ${small ? 'small': large ? 'h3' : ''}`} css={buttonStyles}>
				{children}
			</AnchorLink>
		)
	} else if (to) {
		return (
			<Link className={className + ` button ${small ? 'small': large ? 'h3' : ''}`} css={buttonStyles} to={to} activeClassName={activeClassName}>
				{children}
			</Link>
		)
	} else if (link) {
		return (
			<Wrap className={className + ` button ${small ? 'small': large ? 'h3' : ''}`} css={buttonStyles} link={link} >
				{children}
			</Wrap>
		)
	} else if (onClick) {
		return (
			<WrapFunctional className={className + ` button ${small ? 'small': large ? 'h3' : ''}`} css={buttonStyles} onClick={onClick}>
				{children}
			</WrapFunctional>
		)
	} else {
		return (
			<WrapDiv className={className + ` button ${small ? 'small': large ? 'h3' : ''}`} css={buttonStyles} onClick={onClick}>
				{children}
			</WrapDiv>
		)
	}
}

const Wrap = styled(SanityLink)`
	
`
const WrapFunctional = styled.button`
	
`
const WrapDiv = styled.div`
	
`

Button.propTypes = {
	className: PropTypes.string,
	small: PropTypes.bool,
	large: PropTypes.bool,
	flexi: PropTypes.bool,
	white: PropTypes.bool,
	black: PropTypes.bool,
	blue: PropTypes.bool,
	border: PropTypes.bool,
	children: PropTypes.node,
	to: PropTypes.string,
	link: PropTypes.object,
	activeClassName: PropTypes.string,
	onClick: PropTypes.func,
	anchor: PropTypes.bool,
}

export default Button