import React from 'react'
import { Global, css } from '@emotion/react'
import Button from '~components/Button'
import styled from '@emotion/styled'
import { ChevronRight } from '~components/Svg'
import Image from '~components/Image'
import { Link } from 'gatsby'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: 'Favorit', sans-serif;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.31;
		${tablet}{
			
		}
  }

  :root{
    --white: #ffffff;
		--blue: #0DBAF7;
    --black: #000000;
		--grey: #F4F4F4;
		--darkGrey: #E6E6E6;
		--extraDarkGrey: #7F7F7F;
		--red: #EE4D37;
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
		--greenGradient: linear-gradient(225deg, #0FD16D 2.45%, #0DBAF7 95.75%);
		--pinkGradient: linear-gradient(225deg, #EE4D37 13.23%, #FF92BE 83.09%);
		--purpleGradient: linear-gradient(225deg, #9747FF 18.75%, #0DBAF7 85.42%);
		--yellowGradient: linear-gradient(225deg, #FBC63E 15.1%, #0DBAF7 81.77%);
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}

  p{
    margin-bottom: 1.4em;
    &:last-child{
      margin-bottom: 0;
    }
  }

	.large{
		font-weight: 400;
		line-height: 1;
		font-size: 90px;
		letter-spacing: -0.05em;
	}

  h1,
  .h1 {
		font-weight: 400;
		font-size: 55px;
		line-height: 1;
    ${tablet} {
			font-size: 32px;
			line-height: 1.09;
    }
  }

	h2,
  .h2 {
		font-weight: 400;
		font-size: 30px;
		line-height: 1.17;
    ${tablet} {
			font-size: 26px;
			line-height: 1.15;
    }
  }
	
	h3,
  .h3 {
		font-weight: 400;
		font-size: 22px;
		line-height: 1.25;
    ${tablet} {
			font-size: 21px;
			line-height: 1.19;
    }
  }

	h4,
  .h4 {
		font-weight: 400;
		font-size: 17px;
		line-height: 1.25;
  }
	
	.small {
		font-size: 14px;
		line-height: 1.25;
	}

	.tag {
		font-weight: 400;
		font-size: 14px;
		line-height: 1.25;
		${tablet}{
			font-size: 12px;
		}
	}

	.button {
		font-weight: 400;
	}

	.nav{
		font-size: 16px;
		line-height: 1.12;
		${mobile}{
			font-weight: 400;
			font-size: 20px;
			line-height: 1.1;
		}
	}
`

export const ArrowButton = styled(Button)`
	width: 100%;
	background-color: transparent;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	color: var(--white);
	border-color: var(--white);
	padding: 10px 16px 10px 30px;
	background-color: transparent;
	:hover{
		background-color: var(--white);
		color: var(--black);
	}
`
export const ChevRight = styled(ChevronRight)`
	width: 10px;
`
export const HeaderImage = styled(Image)`
	height: 100vh;
	${mobile}{
		height: 70.5vh;
	}
`
export const HeaderImageDesk = styled(HeaderImage)`
	${mobile}{
		display: ${ props => props.hideMobile ? 'none' : 'block'};
	}
`
export const HeaderImageMobile = styled(Image)`
	display: none;
	${mobile}{
		display: block;
		height: 70.5vh;
	}
`
export const TypeCatLabel = styled.div`
	border-radius: 6px;
	height: 32px;
	display: inline-flex;
	overflow: hidden;
	${tablet}{
		height: 29px;
	}
`
export const TypeLabel = styled(Link)`
	background-color: ${ props => props.grey ? 'var(--grey)' : 'var(--white)'};
	padding: 7px 12px 4px;
	${tablet}{
		padding: 6px 10px 4px;
	}
`
export const CatLabel = styled(Link)`
	background-color: var(--black);
	color: var(--white);
	padding: 7px 12px 4px;
	${tablet}{
		padding: 6px 10px 4px;
	}
`
export const BackLink = styled(Link)`
	display: inline-flex;
	align-items: center;
`
export const ChevronLeft = styled(ChevronRight)`
	transform: rotate(180deg);
	width: 9px;
	margin-right: 10px;
	display: inline-block;
	position: relative;
	top: -1px;
	${mobile}{
		margin-right: 9px;
	}
`
const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

	input, select {
		border-radius: 0;
		-webkit-appearance: none;
		&:focus{
			outline: none;
		}
	}

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }

`

export default GlobalStyles
