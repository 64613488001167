import React, { useRef, useLayoutEffect } from 'react'
import { Loader } from 'google-maps'
import PropTypes from 'prop-types'

const Map = ({ className }) => {
	const wrapRef = useRef()

	useLayoutEffect(() => {
		const loader = new Loader('AIzaSyC8YuOIHVStB_V9BjaqNi98Vffa7vDWPVM', {})

		loader.load().then(function (google) {
			const gMap = new google.maps.Map(wrapRef.current, {
				center: {lat: -37.834914312552975, lng: 144.9631888134938},
				zoom: 16,
				mapTypeControl: true,
				streetViewControl: false,
				fullscreenControl: false,
				zoomControl: true,
				gestureHandling: 'cooperative',
			})

			new google.maps.Marker({
				position: {lat: -37.834914312552975, lng: 144.9631888134938},
				map: gMap,
			})
      
		})

	}, [])

	return(
		<div ref={wrapRef} style={{width: '100%'}} className={className}>
		</div>
	)
}

Map.protoTypes = {
	className: PropTypes.string,
}

export default Map