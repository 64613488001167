import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'

const InlineImage = ({ content }) => {
	return (
		content?.width === 'full' ? 
			<Full>
				<Image image={content?.image}/>
			</Full>
			:
			<ImageRight>
				<Image image={content?.image}/>
			</ImageRight>
	)
}

const Full = styled.div`
	grid-column: span 8;
	border-radius: 15px;
	overflow: hidden;
	margin-top: 50px;
	margin-bottom: 70px;
	margin-left: -110px;
	margin-right: -110px;
	@media (max-width: 1340px){
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: calc(100vw - 20%);
	}
	${mobile}{
		max-width: 100%;
		margin: 37px 0 60px;
	}
`
const ImageRight = styled.div`
	grid-column: 4/9;
	margin: 34px 0 56px;
	border-radius: 15px;
	overflow: hidden;
	${mobile}{
		grid-column: span 8;
		margin: 37px 0 60px;
	}
`

InlineImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default InlineImage