const resolveLink = (doc) => {
	if(!doc) return null
	const type = doc._type
	const slug = doc.slug?.current

	switch (type) {
	case 'article':
		return `/journal/${slug}`
	case 'story':
		return `/stories/${slug}`
	case 'loan':
		return `/loans/${slug}`
	case 'page':
		return `/${slug}`
	case 'category':
		return `/journal/?c=${slug}`
	case 'productType':
		return `/journal/?t=${slug}`
	case 'homePage':
		return '/'
	default:
		return `/${slug}`
	}

}

export default resolveLink
