import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import { motion } from 'framer-motion'
import Button from '~components/Button'
import { useState } from 'react'

const AnimatedNavButton = ({ className, children, isSelected, link, small }) => {

	const [ hover, setHover ] = useState(false)

	return (
		<div 
			className={className} 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Wrap 
				link={link} 
				className='nav'
				small={small}
			>
				<Relative>
					<Text hover={hover}>
						{children}
					</Text>
					<Overlay hover={hover}>
						{children}
					</Overlay>
					<HiddenText hover={hover}>
						{children}
					</HiddenText>
				</Relative>
				{isSelected && 
					<Shadow 
						layoutId="underline"
						transition={{
							type: 'spring',
							stiffness: 200,
							damping: 20
						}}
					/>
				}
			</Wrap>
		</div>
	)
}

const Wrap = styled(Button)`
	margin-right: 6px;
	min-width: 84px;
	position: relative;
	background-color: transparent;
	:hover{
		background-color: transparent;
		color: inherit;
	}
`
const Shadow = styled(motion.div)`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	background-color: var(--grey);
	border-radius: 30px;
	z-index: 0;
`
const Overlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -1;
	background: linear-gradient(to right, #0182C4 0%, #1BB28E 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	opacity: ${ props => props.hover ? '1' : '0'};
	transition: opacity 0.25s;
`
const Relative = styled.div`
	position: relative;
	z-index: 2;
`
const Text = styled.div`
	opacity: ${ props => props.hover ? '0' : '1'};
	transition: opacity 0.25s;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1;
`
const HiddenText = styled.div`
	opacity: 0;
`

AnimatedNavButton.propTypes = {
	className: PropTypes.string,
	children: PropTypes.number,
	isSelected: PropTypes.bool,
	link: PropTypes.object,
}

export default AnimatedNavButton