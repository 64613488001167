import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				mainMenu {
					_key
					link{
						...link
					}
					text
				}
				footerMenu {
					_key
					link{
						...link
					}
					text
				}
				linkedIn
				instagram
				contactHeading
				southMelbourneAddress: _rawSouthMelbourneAddress(resolveReferences: {maxDepth: 5})
				essendonAddress: _rawEssendonAddress(resolveReferences: {maxDepth: 5})
				phoneNumber
				faxNumber
				email
				hours: _rawHours(resolveReferences: {maxDepth: 5})
				contactFormHeading
				contactFormText: _rawContactFormText(resolveReferences: {maxDepth: 5})
				contactFormEmailDestination
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
