import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import Button from '~components/Button'
import styled from '@emotion/styled'
import { mobile, TypeCatLabel, TypeLabel, CatLabel, BackLink , ChevronLeft } from '~styles/global'
import InlineImage from '~components/InlineImage'
import { css } from '@emotion/react'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link 
					to={resolveLink(props.mark.document)} 
					css={css`text-decoration: underline;`}
				>{props.children}</Link> 
			} else {
				return <a 
					href={props.mark?.url} 
					target={props.mark.blank ? '_blank' : '_self'} 
					rel='noreferrer'
					css={css`text-decoration: underline;`}
				>{props.children}</a>
			}
		},
		strong: props => {
			return <span css={css`font-family: 'Favorit'; font-weight: 400;`}>{props.children}</span>
		}
	},
	types: {
		block: props => {
			const {style = 'normal'} = props.node 
			if (style === 'h4') {
				return <h3>{props.children}</h3>
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
		pageHeading: props => {
			return (
				<Left>
					<StyledBackLink to='/journal'>
						<ChevronLeft />Back
					</StyledBackLink>
					<Title>{props.node?.title}</Title>
					<StyledTypeCatLabel>
						<TypeLabel 
							className='tag' 
							grey 
							to={resolveLink(props.node?.productType)}
						>
							{props.node?.productType?.title}
						</TypeLabel>
						<CatLabel 
							className='tag' 
							to={resolveLink(props.node?.category)}
						>
							{props.node?.category?.title}
						</CatLabel>
					</StyledTypeCatLabel>
				</Left>
			)
		},
		button: props => {
			return <StyledButton link={props?.node?.link} border>{props?.node?.text}</StyledButton>
		},
		inlineImage: props => {
			return <InlineImage content={props.node}/>
		},
		quote: props => {
			return <><Quote className='h3'>“{props?.node?.quote}”</Quote><Right /></>
		},
	}
}

const StyledButton = styled(Button)`
	margin-top: 10px;
	margin-bottom: 22px;
	${mobile}{
		width: 100%;
	}
`

const RichText = ({ content, styledText }) => (
	styledText ? 
		<TextStyles><BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/></TextStyles>
		:
		<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
)

const TextStyles = styled.div`
	> div {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		grid-column-gap: 30px;
	}
	p, h3{
		grid-column: 4/9;
		${mobile}{
			grid-column: span 8;
		}
	}
	h3{
		margin-bottom: 24px;
	}
`

const Left = styled.div`
	grid-column: 1/4;
	position: relative;
	${mobile}{
		grid-column: span 8;
	}
`
const Quote = styled(Left)`
	margin-bottom: 20px;
`
const Title = styled.h2`
	margin-bottom: 23px;
`
const Right = styled.div`
	grid-column: 4/9;
	${mobile}{
		grid-column: span 8;
	}
`
const StyledTypeCatLabel = styled(TypeCatLabel)`
	${mobile}{
		margin-bottom: 60px;
	}
`
const StyledBackLink = styled(BackLink)`
	position: absolute;	
	left: -110px;
	@media (max-width: 1346px){
		display: none;
	}
`

RichText.propTypes = {
	content: PropTypes.array,
	styledText: PropTypes.bool,
}

export default RichText
