import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import { useEffect } from 'react'
import Section from '~components/Section'
import RichText from '~components/RichText'
import ContactForm from '~components/ContactForm'
import Footer from '~components/Footer'
import Map from '~components/Map'
import SanityLink from '~components/SanityLink'

const Contact = ({ className, content, show }) => {

	return (
		<Wrap className={className} show={show}>
			<TopSection>
				<Details>
					<HeadingDesk>{content.contactHeading}</HeadingDesk>
					<Address><RichText content={content.southMelbourneAddress}/></Address>
					<Address2><RichText content={content.essendonAddress}/></Address2>
					<ContactBlock>
						<a href={`tel:${content.phoneNumber}`}>T: {content.phoneNumber}</a><br/>
						F: {content.faxNumber}<br/>
						<SanityLink 
							link={{
								url: `mailto:${content.email}`
							}}
						>E: {content.email}</SanityLink>
					</ContactBlock>
					<Hours>
						<RichText content={content.hours}/>
					</Hours>
				</Details>
				<HeadingMobile>
					{content.contactHeading}
				</HeadingMobile>
				<StyledMap />
			</TopSection>
			<BottomSection>
				<Details>
					<Heading>
						{content.contactFormHeading}
					</Heading>
					<Text>
						<RichText content={content.contactFormText}/>
					</Text>
				</Details>
				<Form successMessage={'Received, Thanks!'}/>
			</BottomSection>
			<StyledFooter white/>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 3;
	background: var(--greenGradient);
	opacity: ${ props => props.show ? '1' : '0'};
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	transition: opacity 0.25s;
	color: var(--white);
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
`
const TopSection = styled(Section)`
	border-bottom: 1px solid var(--white);
	> div{
		min-height: 50vh;
		align-content: end;
		padding-top: 220px;
		padding-bottom: 54px;
		box-sizing: border-box;
		${mobile}{
			padding-top: 140px;
			min-height: 0;
			padding-bottom: 0px;
		}
	}
	${mobile}{
		border-bottom: none;
	}
`
const BottomSection = styled(Section)`
	margin-top: 54px;
	margin-bottom: 60px;
`
const Heading = styled.h2`
	grid-column: span 2;
	margin-bottom: 45px;
`
const HeadingDesk = styled.h2`
	grid-column: span 2;
	margin-bottom: 45px;
	${mobile}{
		display: none;
	}
`
const HeadingMobile = styled.h2`
	display: none;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 52px;
		display: block;
		grid-row: 1;
	}
`
const Address = styled.div`
	margin-bottom: 20px;
`
const Address2 = styled.div`
	margin-bottom: 20px;
`
const ContactBlock = styled.div`
	margin-bottom: 10px;
	${mobile}{
		margin-bottom: 20px;
	}
`
const Hours = styled.div`
	margin-bottom: 10px;
`
const StyledMap = styled(Map)`
	grid-column: 7/12;
	height: 0;
	padding-bottom: 47%;
	border-radius: 15px;
	${desktop}{
		grid-column: 7/13;
	}
	${mobile}{
		grid-column: span 12;
		grid-row: 2;
		padding-bottom: 100%;
		margin-bottom: 30px;
	}
`
const Text = styled.div`
	grid-column: span 2;
	align-self: end;
	${mobile}{
		margin-bottom: 28px;
	}
`
const Details = styled.div`
	grid-column: 3/7;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr max-content max-content;
	padding-bottom: 5px;
	grid-column-gap: 30px;
	${desktop}{
		grid-column: 2/7;
	}
	${tablet}{
		grid-column: 1/7;
	}
	${mobile}{
		grid-column: span 12;
		grid-template-columns: 1fr;
	}
`
const Form = styled(ContactForm)`
	grid-column: 7/12;
	${desktop}{
		grid-column: 7/13;
	}
	${mobile}{
		grid-column: span 12;
	}
`
const StyledFooter = styled(Footer)`
	margin-top: auto;
`

Contact.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	show: PropTypes.bool,
}

export default Contact