import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const SanityLink = ({ className, link, children, activeClassName }) => {
	if(link?.linkType === 'internal'){
		return (
			<Link className={className} to={resolveLink(link?.document)} activeClassName={activeClassName}>
				{children}
			</Link>
		)
	} else if(link?.linkType === 'file'){
		return (
			<a href={link?.file?.asset?.url} target={'_blank'} rel='noreferrer' className={className}>
				{children}
			</a>
		)
	} else {
		return (
			<a href={link?.url} target={link?.blank ? '_blank' : '_self'} rel='noreferrer' className={className}>
				{children}
			</a>
		)
	}
}

SanityLink.propTypes = {
	className: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.node,
	activeClassName: PropTypes.string,
}

export default SanityLink