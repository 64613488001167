import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion } from 'framer-motion'
import { C, U, B, E } from '~components/Svg'
import { useSiteState } from '~context/siteContext'
import useBreakpoint from '~utils/useBreakpoint'

const Logo = ({ className }) => {
	
	const [animationState, setAnimationState] = useState(process.env.GATSBY_PREVIEW ? 'natural' : 'hidden')
	const [ siteState ] = useSiteState()
	const stagger = animationState === 'center' || animationState === 'hidden'
	const { isTablet, isMobile } = useBreakpoint()
	const [initialHeight, setInitialHeight] = useState(undefined)

	const aVariants = {
		hidden: { x: isTablet ? 'calc(50vw - 174%)' : 'calc(50vw - 158%)', y: isMobile ? 'calc(50vh - 140px)' : (isTablet ? 'calc(50vh - 115px)' : 'calc(50vh - 124px)'), opacity: 0 },
		center: { x: isTablet ? 'calc(50vw - 174%)' : 'calc(50vw - 158%)', y: isMobile ? 'calc(50vh - 140px)' : (isTablet ? 'calc(50vh - 115px)' : 'calc(50vh - 124px)'), opacity: 1 },
		edge: { x: 0, y: 0, opacity: 1 },
		natural: { x: 0, y: 0, opacity: 1 },
	}
	
	const uVariants = {
		hidden: { x: isTablet ? 'calc(-50vw + 240%)' : 'calc(-50vw + 221%)', y: isMobile ? 'calc(50vh - 140px)' : (isTablet ? 'calc(50vh - 115px)' : 'calc(50vh - 123px)'), opacity: 0 },
		center: { x: isTablet ? 'calc(-50vw + 240%)' : 'calc(-50vw + 221%)', y: isMobile ? 'calc(50vh - 140px)' : (isTablet ? 'calc(50vh - 115px)' : 'calc(50vh - 123px)'), opacity: 1 },
		edge: { x: 0, y: 0, opacity: 1 },
		natural: { x: 0, y: 0, opacity: 1 },
	}
	
	const bVariants = {
		hidden: { x: isTablet ? 'calc(50vw - 190%)' : 'calc(50vw - 167%)', y: isMobile ? 'calc(-50vh + 140px)' : (isTablet ? 'calc(-50vh + 115px)' : 'calc(-50vh + 123px)'), opacity: 0 },
		center: { x: isTablet ? 'calc(50vw - 190%)' : 'calc(50vw - 167%)', y: isMobile ? 'calc(-50vh + 140px)' : (isTablet ? 'calc(-50vh + 115px)' : 'calc(-50vh + 123px)'), opacity: 1 },
		edge: { x: 0, y: 0, opacity: 1 },
		natural: { x: 0, y: 0, opacity: 1 },
	}
	
	const eVariants = {
		hidden: { x: isTablet ? 'calc(-50vw + 237%)' : 'calc(-50vw + 221%)', y: isMobile ? 'calc(-50vh + 140px)' : (isTablet ? 'calc(-50vh + 115px)' : 'calc(-50vh + 123px)'), opacity: 0 },
		center: { x: isTablet ? 'calc(-50vw + 237%)' : 'calc(-50vw + 221%)', y: isMobile ? 'calc(-50vh + 140px)' : (isTablet ? 'calc(-50vh + 115px)' : 'calc(-50vh + 123px)'), opacity: 1 },
		edge: { x: 0, y: 0, opacity: 1 },
		natural: { x: 0, y: 0, opacity: 1 },
	}

	const wrapVariants = {
		center: { background: 'rgba(255, 255, 255, 1)' },
		edge: { background: 'rgba(255, 255, 255, 1)' },
		natural: { background: 'rgba(255, 255, 255, 0)' },
	}

	useEffect(() => {
		if(!process.env.GATSBY_PREVIEW){
			setAnimationState('center')
			window.setTimeout(() => {
				setAnimationState('edge')
			}, 1800)
			window.setTimeout(() => {
				setAnimationState('natural')
			}, 2300)
		}
	}, [])

	useEffect(() => {
		const height = window.innerHeight
		setInitialHeight(height)
	}, [])

	const standardTransition = { type: 'spring', mass: 0.5 }

	return (
		<>
			{(!animationState	=== 'natural') &&
			<Global styles={css`
					html, body {
						overflow: hidden;
					}
				`}/>
			}
			<Wrap 
				className={className}
				animate={animationState}
				initial={process.env.GATSBY_PREVIEW ? 'natural' : 'center'}
				variants={wrapVariants}
				transition={{ duration: 0.5 }}
				white={siteState.menuOpen || siteState.contactOpen}
				initialHeight={initialHeight}
			>
				<LetterC 
					animate={animationState}
					initial={process.env.GATSBY_PREVIEW ? 'natural' : 'hidden'}
					variants={aVariants}
					transition={{...standardTransition, delay: stagger ? 0 : 0}}
				>
					<StyledC />
				</LetterC>
				<LetterU
					animate={animationState}
					initial={process.env.GATSBY_PREVIEW ? 'natural' : 'hidden'}
					variants={uVariants}
					transition={{...standardTransition, delay: stagger ? 0.3 : 0}}
				>
					<StyledU />
				</LetterU>
				<LetterB
					animate={animationState}
					initial={process.env.GATSBY_PREVIEW ? 'natural' : 'hidden'}
					variants={bVariants}
					transition={{...standardTransition, delay: stagger ? 0.6 : 0}}
				>
					<StyledB />
				</LetterB>
				<LetterE
					animate={animationState}
					initial={process.env.GATSBY_PREVIEW ? 'natural' : 'hidden'}
					variants={eVariants}
					transition={{...standardTransition, delay: stagger ? 0.9 : 0}}
				>
					<StyledE />
				</LetterE>
			</Wrap>
		</>
	)
}

const Wrap = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	z-index: 199;
	color: ${ props => props.white ? 'var(--white)' : 'inherit'}; 
	max-height: ${ props => props.initialHeight ? `${props.initialHeight}px` : '100vh'};
`
const LetterC = styled(motion.div)`
	position: fixed;
	top: 23px;
	left: 21px;
	${tablet}{
		top: 18px;
		left: 17px;
	}
`
const LetterU = styled(motion.div)`
	position: fixed;
	top: 23px;
	right: 21px;
	${tablet}{
		top: 20px;
	}
`
const LetterB = styled(motion.div)`
	position: fixed;
	bottom: 23px;
	left: 21px;
	${tablet}{
		bottom: 14px;
		left: 20px;
	}
`
const LetterE = styled(motion.div)`
	position: fixed;
	bottom: 23px;
	right: 21px;
	${tablet}{
		bottom: 14px;
		right: 20px;
	}
`
const StyledC = styled(C)`
	width: 72px;
	${tablet}{
		max-width: 49px;
	}
`
const StyledU = styled(U)`
	width: 64px;
	${tablet}{
		max-width: 43px;
	}
`
const StyledB = styled(B)`
	width: 66px;
	${tablet}{
		max-width: 45px;
	}
`
const StyledE = styled(E)`
	width: 64px;
	${tablet}{
		max-width: 43px;
	}
`

Logo.propTypes = {
	className: PropTypes.string
}

export default Logo